<template>
  <div class="container">
    <checkout-container />
  </div>
</template>

<script>
  import CheckoutContainer from 'src/shared/features/checkout/checkout.container';

  export default {
    name: 'CheckoutView',
    components: {
      CheckoutContainer,
    },
  };
</script>
